<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-file-compare"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Versões da BDGD</div>
          </template>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />
          <v-divider class="mt-10" />
          <v-data-table
            :headers="headers"
            :sort-by="['data_registro', 'versao']"
            :sort-desc="['data_registro', 'versao']"
            :items="items"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            :mobile-breakpoint="800"
            no-data-text="Nenhuma versão de BDGD encontrada"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template>
            <template v-slot:[`item.data_registro`]="{ item }">
              {{ item.data_registro | formatToMonth }} v{{ item.versao }}
              {{ item.modulo10_antigo ? '(Módulo 10 Antigo)' : '' }}
            </template>
            <template v-slot:[`item.titulo`]="{ item }">
              <v-responsive max-width="170">
                <v-text-field
                  v-model="item.titulo"
                  @blur="setTituloVersao(item.id, item.titulo)"
                />
              </v-responsive>
            </template>
            <template v-slot:[`item.registros`]="{ item }">
              {{ item.registros | parseNumberToIntegerBR }}
            </template>
            <template v-slot:[`item.ordinaria`]="{ item }">
              <bdgd-ordinaria :ordinaria="item.ordinaria" />
            </template>
            <template v-slot:[`item.cor`]="{ item }">
              <v-responsive max-width="200">
                <input-color
                  :label="null"
                  :selectedColor="item.cor"
                  @colorSelected="(color) => setCorBdgd(item.id, color)"
                />
              </v-responsive>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="usuarioAdmin && item.registros > 0"
                fab
                icon
                x-small
                :color="item.ordinaria ? 'red' : 'green'"
                :title="
                  item.ordinaria
                    ? 'Definir como não ordinária'
                    : 'Definir como ordinária'
                "
                @click="() => inverteBdgdOrdinaria(item.id)"
              >
                <v-icon v-if="item.ordinaria">mdi-thumb-down-outline</v-icon>
                <v-icon v-else>mdi-thumb-up-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="accessReleased('VALIDACOES_DA_BDGD_STATUS_GERAL') && item.registros > 0"
                fab
                icon
                x-small
                title="Status da BDGD"
                @click="
                  $router.push(
                    `/geracao-bdgd/validacoes-bdgd/status-geral-bdgd/${item.id}`
                  )
                "
              >
                <v-icon>mdi-database-eye-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="accessReleased('VALIDACOES_DA_BDGD_PAINEL_IQ_BDGD') && item.registros > 0" 
                fab
                icon
                x-small
                title="Painel IQ_BDGD"
                @click="
                  $router.push(
                    `/geracao-bdgd/validacoes-bdgd/painel-iq-bdgd/${item.id}`
                  )
                "
              >
                <v-icon>mdi-numeric-5-box-multiple-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="usuarioAdmin && item.registros > 0" 
                fab
                icon
                x-small
                title="Duplicar BDGD"
                @click="() => duplicarBdgd(item.id)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getVersoesBdgds"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar as versões da BDGD</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import BdgdVersionsService from '@/services/BdgdVersionsService.js';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
import BdgdOrdinaria from '@/components/general/BdgdOrdinaria';
import InputColor from '@/components/general/InputColor';

export default {
  name: 'VersoesBdgdsHistorico',
  mixins: [profilePermissionsMixin],
  components: {
    BdgdOrdinaria,
    InputColor
  },
  data: () => ({
    items: [],
    search: '',
    loading: false,
    usuarioAdmin: false
  }),
  mounted() {
    this.getUsuarioAdmin();
    this.getVersoesBdgds();
  },
  computed: {
    empresa() {
      return this.$store.getters.getSelectedCompany;
    },
    headers() {
      let headers = [
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Mês dos dados',
          value: 'data_registro'
        },
        {
          text: 'Titulo da BDGD',
          value: 'titulo'
        },
        {
          text: 'Nº de Linhas',
          value: 'registros'
        },
        {
          text: 'BDGD Ordinária?',
          value: 'ordinaria'
        },
        {
          text: 'Cor da BDGD',
          value: 'cor'
        }
      ];
      if (this.usuarioAdmin) {
        headers.push({
          sortable: false,
          text: 'Ações',
          value: 'actions',
          align: 'center'
        });
      }
      return headers;
    }
  },
  methods: {
    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    },
    getVersoesBdgds() {
      this.loading = true;
      BdgdVersionsService.getDatesAndRegistersImportedEntitiesByCompanyId(
        this.empresa.id
      )
        .then((response) => (this.items = response.data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar as versões das BDGDs.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },
    inverteBdgdOrdinaria(id) {
      this.loading = true;
      BdgdVersionsService.reverseOrdinaryBdgdVersion(id)
        .then(() => {
          this.$toast.success(
            'A versão da BDGD foi atualizada com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.getVersoesBdgds();
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao atualizar a versão da BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => {
          this.loading = false;
          this.getVersoesBdgds();
        });
    },
    setCorBdgd(bdgdVersaoId, novaCorBdgd) {
      BdgdVersionsService.setColor(bdgdVersaoId, novaCorBdgd)
        .then(() => {
          this.$toast.success('Nova cor da BDGD salva com sucesso.', '', {
            position: 'topRight'
          });
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao tentar salvar a nova cor da BDGD.', '', {
            position: 'topRight'
          });
        });
    },
    setTituloVersao(bdgdVersaoId, titulo) {
      BdgdVersionsService.setTitle(bdgdVersaoId, titulo)
        .then(() => {
          this.$toast.success(
            'Titulo da versão da BDGD salvo com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao tentar salvar o titulo da versão da BDGD.',
            '',
            {
              position: 'topRight'
            }
          );
        });
    },
    duplicarBdgd(id) {
      this.loading = true;
      BdgdVersionsService.duplicateBdgdVersion(id)
        .then(() => {
          this.$toast.success(
            'A versão da BDGD selecionada será duplicada, por favor aguarde.',
            '',
            { position: 'topRight' }
          );
          this.getVersoesBdgds();
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao duplicar a versão da BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => {
          this.loading = false;
          this.getVersoesBdgds();
        });
    }
  }
};
</script>
